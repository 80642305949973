* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


html {
  font-size: 62.5%; 
  /* // 1rem = 10px */

  @include breakpoint(medium) {
    font-size: 56.25%;  
    /* 1rem = 9px */
  }

  @include breakpoint(small) {
    font-size:
     /* 50%; // 1rem = 8px */
  }
}
body {
  /* font-family: "Poppins", sans-serif; */
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  background: #f2f8fb;
  /* padding-top: 60px; */  
  min-height: calc(100vh - 0px);
}
.bgdarkgreen{
  background: green;
}
.textgreen{
  color: green;
}
.textred{
  color: red;
}
li{
  list-style: none;
}
a:hover{
  text-decoration: none;
}

.mainheading {
  background: #717171;
  padding: .7rem;
  color: #fff;
  font-weight: bold;
}
.mainheading2{
  background: #717171;
  padding: .7rem;
  color: #0c1f6e;
  font-weight: bold;

}

/* wrapper start start*/
.wrapper{
  min-height: calc(100vh - 155px);
  /* display: flex;
    align-items: center;
    justify-content: center; */
}

.wrappermax{
  min-height: calc(100vh - 216px);
}
.innerwrapper{
  min-height: calc(100vh - 216px);
}
.wrapper2{
  padding-bottom: 40px;  
}
.btn{
  background: #0077ae;
  padding: 6px 10px;
  border: none!important;
  border-radius: 0;
}
.fix-topheader{
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  animation: smoothScroll 1s forwards;
  z-index:9999;
}
@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}
.clientportal .top_header{
  padding: 0px;
}
.clientportal .top_header .header-wrap{
  padding: 10px;
}
.mainArea{
  padding: 1.5rem!important;
  position: relative;
}
.lefeArea{
  padding: 0rem!important;
}
.rightArea{
  padding: 0 1.9rem!important;
}
.leftmenuclient li:last-child{
  border: none;
}
.rightArea img{
  max-width: 200px;
  margin: 0 auto;
  display: block;
}
.right{
  position: absolute;
  right: 0;
  top: 1px;
}
.graybg{
  background: #e2e0e2;
  padding: 1rem;
}
.bluebg{
  background: #0077ae;
  
}
.bgorg{
  background: #ff6034!important;
}
.smallheading{
  color: #ffffff;
  font-weight: bold;
  background: #717171;
  padding: 5px 10px;
  position: relative;
}
/* lefeArea start */
.lefeArea{
}
.tablebox{
  margin: 1.5rem 0;
  background: #fff;
  padding: 1rem;
  font-size: 1.2rem;
  position: relative;
}
#formpage .tablebox{
  background: #fff;
  margin-top: 0;
}
.smallheading {
  margin: 1rem 0;
}
#formpage .tablebox2{
  background: #fff;
}
.tablebox thead{
  background: #0077ae;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  border: none;
  box-shadow: none;
}
.colortext{
  color: #0036f3;
}
.bgyellow{
  background: #fbfbd8;
}
.bggreen{
  background: #c7e9ca;
  text-align: center;
}
.bggreenlight{
  background: #d9ebd4;
  text-align: center;
}
.bglightred{
  background: #f8c6c1;
  text-align: center;
}
.bglightgreen{
  background: #dcedd9;
  text-align: center;
}
.tablebox button i{  
  padding: .3rem ;
  
  font-size:1.5rem!important;
}
.tablebox button a{
  color: #fff;
  display: block;
}
.tablebox button a:hover{
  background: #007bff;
}
/* lefeArea end */
/* wrapper end*/
.styling {
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  /* height: 150px;
  overflow: auto; */
}
.table td, .table th {
  padding: .75rem;
  vertical-align: middle;
}
.table td, .table tr {
  padding: .75rem;
  vertical-align: middle;
  text-transform: none !important;
}
.tablebox .form-group div{
  /* padding: 0.6rem; */
}
.form-group {
  /* margin-bottom: 0.6rem; */
}
.form-control {
  font-size: 1.4rem;
  /* width: auto; */
  height: 30px;
}

.btn{
  font-size: 1.2rem;
}
.form-group {
  height: 35px;
  font-size: 1.2rem;
  margin-bottom: 14px;
}
.form-group label{
  line-height: 29px;
}
#formpage .form-group label{
  text-align: right;
}
.contentarea{
  background-color: #fff;
  padding: 1rem;
  margin-top: 2rem;
}
.contentarea h1{
  text-align: center;
  padding: 4rem;
}
.navbar .active a{
  background: #0c1f6e;
}
select.form-control:not([size]):not([multiple]){
  height: 30px;
}

sup{
  font-size: 1.6rem;
  color:#f00;
  top: 0;
}


.bottomarea{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.loginfooter {
  width: 100%;
  background: #717171;
  padding: 1rem;
  font-size: 1.4rem;
  position: relative;
  bottom: 0;
}
.loginfooter p{ 
  color: #fff;
}
.loginfooter a{ 
  color: #0077ae;
}
.loginfooterfixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #717171;
  padding: 1rem;
  font-size: 1.4rem;
}
.massege{
  position: relative;
}
.massege .badge{
  position: absolute!important;
  background: #ff6034;
  top: -7px!important;
  left: 19px!important;
  border-radius: 10px;
  font-size: 1.1rem!important;
}
.messegeHeading{
  text-align: center;
  /* padding: 1.5rem; */
  font-weight: bold;
  background: #fff;
}
.messegeHeading h4{
  margin: 0;
  padding: 1rem;
  font-weight: bold;
}
.messegeHeading i{
  color: #007bff;
  font-size: 2rem;
  margin-left: 1rem;
}
.license{
  margin: 0 auto;
  margin-top: 10rem;
  width: 60%;
}
.license a{
  color: #fff;
  background: #0077ae;
  padding: 1.5rem;
  display: block;
  font-size: 1.6rem;
}

.tablebox button{
  background: #0077ae;
  color: #fff;
  border: none;
  padding: 7px 10px;
  margin: 2px;
  margin-right: 0;
  font-weight: bold;
}
.viewbutton {
  background: #0a206c;
  color: #fff;
  border: none;
  padding:4px;
  text-align: center;
  display: block;
}
.tablebox tr button{
}
.tablebox tr button a{
  padding: 4px;
}
.greybtn{
  background: #e1e1e1;
  color: #000;
}
.greybtn:hover{
  background: #0077ae!important;
  color: #fff;
}
#demo{
  padding: 1.5rem;
  background: #fff;
}
.clientteaminput input{
  width: 100%;
}
.clientteaminput select{
  width: 100%;
}
.btnwrp{
  display: flex;
  justify-content: center;
}
.gj-datepicker button{
  background: none;
  color: #000;
}
.gj-datepicker button:focus{
  background: none;
  color: #000;
}
.gj-datepicker i{
  position: absolute;
    font-size: 25px;
    top: -4px!important;
    left: 1px!important;
    color: transparent;
    width: 30px;
    overflow: hidden;
  }
  .gj-datepicker i:before {
    content: "\f073";
    font-family: 'FontAwesome';
    color: #000;
}

.inputfullwidth{
  margin-top: 4rem;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0d2887;
}
.inputfullwidth input{
  width: 100%;
  padding: 1rem;
  line-height: 21px;
  background: #edeced;
  border: none;
}
.custom-radio label{
  background: none!important;
  font-size: 1.4rem;
}
.bgred{
  background: #f00!important;
  text-align: center;
  color: #fff;
}
.bgred:hover{
  background: rgb(148, 1, 1)!important;
  text-align: center;
}
.form-check-input {
  margin-left: -16px;
}
.button{
  background: #0077ae;
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
}
.button:hover{color: #fff; background: #0036f3;}
.height0{
  height:15px;
}
.bluecolor{
  color: #0036f3;
  font-size: 1.4rem;
  margin: 0;
}
.blackolor{
  color: #000000;
  font-size: 1.4rem;
  margin: 0;
}
.blackolor {
  margin-top: 8px;
}
.w100{
  width: 100%;
}
.mmdown button{
  background: #fff;
  color: #000;
  font-size: 1.4rem;
  padding: .5rem;
}
.mmdown button i{
  padding: 0rem;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
  border: 1px solid #6c757d;
}
.adminmenu{
  margin-left: .6rem;
}
.adminmenu a{
  color: #fff;
  /* border: 1px solid #fff; */
  display: block;
  padding: .5rem 1rem!important;
  font-size: 1.6rem;
}
.adminmenu a i{
  font-size: 1.8rem;
  margin-right: 1rem;
}
.adminmenu li{  
  transition: all 0.5s;
}
.adminmenu li:hover{
  padding-left: 1rem;
}
.loadbtnfixed input{
  line-height: 23px;
  font-size: 1.4rem;
}
.btn{
  cursor: pointer;
}
.widthauto{
  width: inherit;
}

.btnwrp .btn{
  font-weight: bold;
  padding: 7px 8px;
  font-size: 16px;
}
.customdatesm{
  position: relative;
}
.customdatesm input{
  margin-bottom: 0px!important;
  position: relative;
}
.customdatesm input::after {
  z-index: 9999;
  content: "\f133";
  display: block;
  font-size: 2rem;
  color: STEELBLUE;
  background: none;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 13px;
  left: 0px;
  font-family: 'FontAwesome';
}
.customdatesm button{
  background:none;
  padding: 0;
  border: 0.45rem solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: rgb(0, 0, 0)!important;
}
.react-datepicker__navigation--next {
  border-left-color: rgb(0, 0, 0)!important;
}
.react-datepicker-popper {
  z-index: 1;
  transform: scale(1.2)!important;
  transform-origin: top;
  left: 36px!important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 40px!important;
}
#formpage .tablebox{
  /* margin-bottom: 100px; */
}
.accountbox-wrap {
  position: fixed;
  background: #fff;
  max-width:60%;
  height: 60%;
  width: 100%;
  z-index: 99;
  padding: 15px 30px;
  border: 5px solid #717171;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 5px #000;
  text-align: center;
}
.accountbox-wrap input {
  font-size: 1.4rem;
  /* width: auto; */
  height: 30px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.accountbox-wrap i{
  background: #717171;
    color: #000;
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 5px;
    font-size: 15px;
    display: block;
    cursor: pointer;    
  border: 5px solid #717171;
}
.account_search_wrap{
  /* display: flex; */
}
.accountbox-wrap i:hover{
  background-color: #0c1f6e;
  color: #fff;
  border: 5px solid #717171;
}
.accountbox-wrap button{
  margin-top: 20px;
}
.accountbox-wrap ul {
  list-style: none;
}
.accountbox-wrap ul li { 
  line-height: normal;
}
.accountbox-wrap ul li a {
  line-height: normal;
  color:#000;
  font-weight: 600; 
  padding: 10px 5px;
  display: block;
}
.accountbox-wrap ul li:hover{
  background: #ccc;
}
.accountbox-wrap .search_input {
  width: 100%;
  text-indent: 5px;
  margin: 5px;
  margin-top: 20px;
}
.accountlist {
  background-color: #fbfafb;
  height:90%;
  margin-top: 20px;
  overflow: auto;
  padding: 0px;
}
.accountlist li{
  border-top: 1px solid #dee2e6;
}
.error-display {
  clear: both;
  display: inline-block;
  width: 100%;
  text-align: left;
  /* margin-bottom: -15px; */
  height: 17px;
  position: relative;
  /* background: #f00; */
  margin-left: 32px;
  margin-top: -8px;
  font-weight: bold;
}
i.fa.fa-pencil {
  margin-right: 10px;
  background: #0077ae;
  color: #fff;
  padding: 5px;
  cursor: pointer;
}
.btn{
  margin: auto 2px;
}
 
.error {
  position: absolute;
  top: 31px;
  line-height: normal;
  color: red;
  font-size: 12px;
  padding: 2px;
  /* left: 2px; */
  font-weight: bold;
  width: 210px;
  text-transform: capitalize;
}
.error-login {
  position: absolute;
  top: 30px;
  line-height: normal;
  color: red;
  font-size: 12px;
  padding: 2px;
  left: 31px;
  font-weight: bold;
  text-transform: capitalize;
}
.errorm{
  top: 52px;
}
.mleft7{
  margin-left: 7px;
}
.comapny_box .row{
  padding-top: 1.25rem;
}
 
.debitarea{
  background: #9c0303;
  color: #fff;
  padding: 2rem;
}
.pofile-image{
  text-align: center;
}
.craditarea{
  background: #039c24;
  color: #fff;
  padding: 2rem;
}
.table{
  background: #fff;
  margin-bottom: 0;
}
.buttonwrp a{
  background: #0077ae;
  color: #fff;
  border: none;
  padding: 7px 10px;
  margin-left: 2px;
  font-weight: bold;
}
.btn2{
  background:none!important;
  padding: inherit!important;
}
.dropboxform {
  padding: 2rem 1rem;
}
.logininner .form-group {
  height: 34px;
}
.client-details-top-menu {
  margin-bottom: 10px;
  padding: 0;
  background: #0077ae;
}
.wrpinput input{
  margin-bottom: 20px;
}
.wrpinput button{
  margin-top: 0;
}
.col-12.text-right.p-0{
  margin-bottom: 10px;
}
textarea{
  height: auto!important;
}
.lefeArea2 label {
  line-height: inherit!important;
}
.rightArea2 label {
  line-height: inherit!important;
}
.rightArea2 .custom-control-inline {
  margin-top: 4px;
}
.leftmenuclient3 li {
  width: 33.33333333333333%;
}
.tableboxwrp input{
  margin-bottom: 20px;
}
.preload{
  height:50px;
  width:50px;
  border-radius: 50%;
  border:10px solid #e5e5e5;
  animation:3s pre linear infinite;
}
@keyframes pre {
  0%{
      transform:rotate(0deg);
      border-top:10px solid #0077ae;
  }
  100%{
      transform:rotate(360deg);
      border-top:10px solid #0077ae;
  }
}
.loader_wrap{
  position: absolute;
    width: 100%;
    height: 100%;
    background: #f2f8fb;
    z-index: 99;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.loader_wrap.top-20{
  top: 20%;
}
.loader_active{
  display: flex;
}
button{
  cursor: pointer;
  position: relative;
}
.btncenter button {
  background: #0077ae;
  color: #fff;
  border: none;
  padding: 7px 10px;
  margin: 2px;
  margin-right: 0;
  font-weight: bold;
}
.btncenter a {
  background: #0077ae;
  color: #fff;
  border: none;
  padding: 7px 10px;
  margin: 2px;
  margin-right: 0;
  font-weight: bold;
}
label {
  text-transform: capitalize;
}
.table td, .table th {
  text-transform: capitalize;
}
.buttonloader .loader_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f2f8fb82;
  z-index: 99;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  top: 0;
  cursor: none!important;
}
.buttonloader .loader_wrap.loader_active{  display: flex;}
.buttonloader .preload {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 4px solid #e5e5e5;
  animation: 1s pre2 linear infinite;
  margin-left: 5px;
  opacity: 0.8;
}
@keyframes pre2 {
  0%{
      transform:rotate(0deg);
      border-top:4px solid #0077ae;
  }
  100%{
      transform:rotate(360deg);
      border-top:4px solid #0077ae;
  }
}
button:focus {
  outline: none;
}
.content-wrap {position: relative;}
#accordionCharges {
  font-size: 1.4rem;
}
#accordionCharges .collapsed{
  cursor: pointer;
}
#accordionCharges .form-group2{
  padding-top: 1rem;
 }
 
 #accordionCharges a[aria-expanded="true"] i{
   color: #007bff;
  background-color: #007bff;
  content: "\f111";
  border-radius: 10px;
  font-family: 'FontAwesome';
  width: 13px;
  height: 13px;
}


.panel,
.panel-body {
  box-shadow: none;
}

.panel-group .panel-heading {
  padding: 0;
}

.panel-group .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  position: relative;
  color: #0c1f6e;
    font-weight: bold;
    padding-left: 0;
}

.panel-group .panel-heading a:after {
  content: "\f068";
  font-family: 'FontAwesome';
  float: right;
}

.panel-group .panel-heading a.collapsed:after {
  content: "\f067";
  font-family: 'FontAwesome';
  float: right;
}
.client_account_checkbox_wrap {
  height: 200px;
  overflow: auto;
  margin-bottom: 20px;
}
.print-btn {
  background: #0077ae;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin: 2px;
    margin-right: 0; 
    line-height: normal;
    display: inline-block;
}
.print-btn:hover{
  color: #fff;
}
.currency-name{
  margin: 0 10px;
  font-weight: bold;
  font-size: 15px;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  /* html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  } */
}
body {
  -webkit-print-color-adjust: exact !important;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  table{
    width: 100%;
  }
  table tr td{
    width: 16.66666666666667%;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .tablebox thead { 
    color: #0077ae!important;
    text-align: center;
    font-size: 1.2rem;
  }
  .tablebox .bgdarkgreen { 
    color: #008000!important;
  }
  .tablebox .bgred { 
    color: #ff0000!important;
  }
}

@page {
  size: auto;
  margin: 10mm;
}
.bg-orange{
  background-color: #fc7d5f;
}
.bg-warning {
  background-color: #e8af01!important;
}
.bg-primary{
  background-color: #d800a2!important;  
}

.deskboxywrp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 225px);
}
.deskboxy{
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.deskboxy .iconwrp{
  background: #0077ae;
  color: #fff;
  font-size: 40px;
  padding: 16px;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%, 100% 100%, 0 81%);
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%, 100% 100%, 0 61%);
}
.deskboxy .deskbox .deskbtn:hover{
  background: #0077ae;
  color: #fff;
}


.deskboxy .deskbox{
  display: block;
  color: #0077ae;
  font-size: 20px;
  background-color: #ffffff;
  border: 2px solid #0077ae;
}
.deskboxy .deskbox .deskbtn{
  margin-top: 16px;
  background: #ffffff;
  display: inline-block;
  color: #0077ae;
  padding: 2px 25px;
  border: 2px solid #0077ae;
  border-bottom: 0;  
}

.small-box {
  border-radius: .25rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  display: block;
  position: relative;
  text-align: left;
  color: #fff;
  transition: all .2s linear;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    font-weight: bold;
    margin-top: 20px;
}
.small-box>.inner {
  padding: 10px;
}
.small-box h3, .small-box p {
  z-index: 5;
}
.small-box h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0;
  white-space: nowrap;
}
.small-box p {
}
.small-box .icon {
  color: rgba(255, 255, 255, 0.35);
  z-index: 0;
  font-weight: normal;
}
.small-box>.small-box-footer {
  background-color: rgba(0,0,0,.1);
  color: rgba(255,255,255,.8);
  display: block;
  padding: 3px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 10;
  font-size: 16px;
}
.small-box .icon>i.fa, .small-box .icon>i.fab, .small-box .icon>i.fad, .small-box .icon>i.fal, .small-box .icon>i.far, .small-box .icon>i.fas, .small-box .icon>i.ion {
  font-size: 70px;
  top: 20px;
}
.small-box .icon>i {
  font-size: 90px;
  position: absolute;
  right: 15px;
  top: 0px!important;
  transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transition: transform .3s linear,-webkit-transform .3s linear;
  font-weight: normal;
}
.ion-bag:before {
  font-family: 'FontAwesome';
  content: "\f2b9";
}

.active-client:before {
  font-family: 'FontAwesome';
  content: "\f007";
}
.didactive:before {
  font-family: 'FontAwesome';
  content: "\f235";
}
.ion-products:before {
  font-family: 'FontAwesome';
  content: "\f288";
}

.activeproducts:before {
  font-family: 'FontAwesome';
  content: "\f05d";
}
.deactiveproducts:before {
  font-family: 'FontAwesome';
  content: "\f057";
}
.resentt:before {
  font-family: 'FontAwesome';
  content: "\f0ec";
}
.small-box>.small-box-footer:hover {
  background-color: rgba(0,0,0,.15);
  color: #fff;
}
.small-box:hover .icon i{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.dataTables_info {
  padding-top: 5px;
}
.card-footer{
  border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    background: #fff;
    padding: 12px 15px 12px;
}
.pagination {
  justify-content: center;
  margin: 0;
  
}

ul.pagination {
  list-style: none;
  
}

ul.pagination li {
    display: inline-block;
    border: 1px solid #717171;
    display: flex;
    justify-content: center;
    font-size: 25px;
    padding: 1px 10px; 
}
ul.pagination li:hover { 
    border: 1px solid #0077ae; 
}

ul.pagination li a {
     text-decoration: none;
    color: #333;
    font-size: 15px;
}

ul.pagination li.active a {
  color: #fff;
}
ul.pagination li:hover a {
  color: #fff;
}
ul.pagination li.active, ul.pagination li:hover {
  background-color: #0077ae;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: #fff;

}

.page-selection {
  width: 48px;
  height: 30px;
  color: #0077ae;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
 
.tablebox button:hover {
background: #717171!important;
}
btn-primary:hover {
color: #fff;
background-color: #717171!important;
}
.accountbox-wrapi:hover {
background-color: #0077ae;
color: #fff;
border: 5pxsolid#717171;
}
.accountbox-wrapi:hover {
background-color: #0077ae;
color: #fff;
border: 5pxsolid#717171;
}
button:hover {
background: #717171!important;
}
button.hamburger.animated.fadeInLeft.is-closed:hover {
  background: #f000!important;
}
.hamburger:active, .hamburger:focus, .hamburger:hover {
  background: #f000!important;
}
.account-total-wrap {
  text-align: center;
   background: #fff;
     margin-bottom: 15px;
}
.account-total-wrap .reportDate {
  text-align: center;
  font-weight: bold; 
  padding: 15px;
  padding-top: 25px; 
   
}
.account-list {
    display: inline-block; 
    padding: 0;
   
}
.account-list > li {
 display: inline-flex;
padding: 12px 50px 12px 70px;
position: relative;
background: #0077ae;
margin: 10px;
color: #fff;
}
.account-list > li p {
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;

}
.account-list > li p label {
  position: absolute;
    left: 0;
    background: #ccc;
    top: 0;
    height: 100%;
    width: 59px;
    padding: 14px 0px;
    font-size: 20px;
        color: #717171;
}
.account-list > li p span {
  font-size: 18px; 
}