
/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  #wrapper.toggled {
    padding-left: 220px;
  }
   
  #sidebar-wrapper {
    z-index: 99999;
    left: 220px;
    width: 0;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #0077ae;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  #sidebar-wrapper::-webkit-scrollbar {
  display: none;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 220px;
  }
  
  #page-content-wrapper {
    width: 100%;
  }
  
  #wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -220px;
  }
  
  /*-------------------------------*/
  /*     Sidebar nav styles        */
  /*-------------------------------*/
  .navbar {
  padding: 0;
  }
  
  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 220px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .sidebar-nav li {
    position: relative; 
    line-height: 20px;
    display: inline-block;
    width: 100%;
  }
  
  
  .sidebar-nav li a:hover{
    color: #fff;
    background-color: #e6ad10!important;
  }
  
  .sidebar-nav li:hover:before,
  .sidebar-nav li.open:hover:before {
    width: 100%;
    -webkit-transition: width .2s ease-in;
      -moz-transition:  width .2s ease-in;
       -ms-transition:  width .2s ease-in;
            transition: width .2s ease-in;
  
  }
  
  .sidebar-nav li a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 10px 15px 10px 30px;    
  }
  .sidebar-nav li i{
      margin-right: 5px;
  }
  
  .sidebar-nav li a:hover,
  .sidebar-nav li a:active,
  .sidebar-nav li a:focus,
  .sidebar-nav li.open a:hover,
  .sidebar-nav li.open a:active,
  .sidebar-nav li.open a:focus{
    color: #fff;
    text-decoration: none;
    background-color: transparent;
  }
  .sidebar-header {
    text-align: center;
    font-size: 20px;
    position: relative;
    width: 100%;
    display: inline-block;
  }
  .sidebar-brand {
    height: 65px;
    position: relative;
    background: #fff;
    /* background:#212531;
    background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%); */
   padding-top: 10px;
  }
  .sidebar-brand img {
    height: 40px;
  }
  .sidebar-brand a {
    color: #ddd;
  }
  .sidebar-brand a:hover {
    color: #fff;
    text-decoration: none;
  }
  .dropdown-header {
    text-align: center;
    font-size: 1em;
    color: #ddd;
    background:#212531;
    background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%);
  }
  .sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
  }
  .dropdown-menu.show {
    top: 0;
  }
  /*-------------------------------*/
  /*       Hamburger-Cross         */
  /*-------------------------------*/
  
  .menubox{
    background: #0077ae;
    height: 100vh;
    width: 3%;
    position: fixed;
    bottom: 0;
    color: #fff;
    z-index: 999;
  }
  .hamburger {
    position: absolute;
    top: 0px;
    z-index: 999;
    display: block;
    width: 25px;
    height: 30px;
    margin-left: 7px;
    background: transparent;
    border: none;
    top: 63px;
    cursor: pointer;
    color: #fff;
  }
  button.hamburger.animated.fadeInLeft.is-open{
      left: 213px;
  }
  .hamburger:hover,
  .hamburger:focus,
  .hamburger:active {
    outline: none;
  }
  .hamburger.is-closed:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed:hover:before {
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(-100px,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  
  .hamburger.is-closed .hamb-top,
  .hamburger.is-closed .hamb-middle,
  .hamburger.is-closed .hamb-bottom,
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-middle,
  .hamburger.is-open .hamb-bottom {
    position: absolute;
    left: 0;
    height: 4px;
    width: 100%;
  }
  .hamburger.is-closed .hamb-top,
  .hamburger.is-closed .hamb-middle,
  .hamburger.is-closed .hamb-bottom {
    background-color: #1a1a1a;
  }
  .hamburger.is-closed .hamb-top { 
    top: 5px; 
    -webkit-transition: all .35s ease-in-out;
    background: #fff;
  }
  .hamburger.is-closed .hamb-middle {
    top: 50%;
    margin-top: -2px;
    background: #fff;
  }
  .hamburger.is-closed .hamb-bottom {
    bottom: 5px;  
    -webkit-transition: all .35s ease-in-out;
    background: #fff;
  }
  
  .hamburger.is-closed:hover .hamb-top {
    top: 0;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed:hover .hamb-bottom {
    bottom: 0;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-middle,
  .hamburger.is-open .hamb-bottom {
    background-color: #1a1a1a;
  }
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-bottom {
    top: 50%;
    margin-top: -2px;  
  }
  .hamburger.is-open .hamb-top { 
    -webkit-transform: rotate(45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
  }
  .hamburger.is-open .hamb-middle { display: none; }
  .hamburger.is-open .hamb-bottom {
    -webkit-transform: rotate(-45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
  }
  .hamburger.is-open:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-open:hover:before {
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(-100px,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  
  /*-------------------------------*/
  /*            Overlay            */
  /*-------------------------------*/
  
  .overlay {
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.4);
      z-index: 1;
  }
  .sidebar-brand{
      color: #fff;
  }
  @media (max-width:768px){
    .menubox{
      /* background: #0096d1; */
      height: 40px;
      width: 45px;
      top: 10px;
      color: #fff;
      z-index: 9999;
      left: 5px;
    }
    .hamburger {
      position: absolute;
      top: 3px!important;
      z-index: 999;
      display: block;
      width: 32px;
      height: 32px;
      margin-left: 6px;
      background: transparent;
      border: none;
      top: 63px;
      cursor: pointer;
      color: #fff;
    }
    .mainheading {
      background: #717171;
      padding: .7rem;
      color: #fff;
      font-weight: bold;
      max-width: 100%;
      margin-left: 0px;
  }
  .mainheading{
    margin-left: 0;
  }
  }