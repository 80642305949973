.receipt-print-wrap{
  width: 1024px;
  margin: 25px auto;
  background: #ffff;
  padding: 25px;
  height: 100vh;
}
@page {
    size: auto;
    margin: 10mm;
  }
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  @page {
    size: auto;
    margin: 10mm;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}


.print-logo {
  width: 80%;
}
.receipt-print-wrap p {
 margin: 0;

}
.receipt-print-wrap strong {
  font-size: 18px;
}
.p-text {
  font-size: 18px;
  line-height: normal;
}
.print-tbl-border {
  border: 1px solid #000;
  width: 100%;
}
.print-tbl-border tr {
   border: 1px solid #000;
}
.print-tbl-border tr td{
   border: 1px solid #000;
   padding: 5px;
   font-size: 14px;
   color: #000;
   line-height: normal;

}
.print-tbl-border tr td p {
  line-height: normal;
}
.print-label {
  font-size: 15px;
  font-weight: bold;
  color: #0097d4;
}