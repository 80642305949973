.newContainer{
    max-width: 96%;
    margin-left: 4%;
    padding-top: 0px!important;
}
.heading{
    padding: .8rem 0;
    text-align: center;
    background: #0077ae;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
    margin-bottom: 10px;
}
.lefeArea {
    padding: 0rem!important;
}
.rightArea {
    padding: 0rem!important;
}
.leftmenuclient a {
    color: #fff;
    background: #32393d;
}
.leftmenuclient li{
    width: 16.66666666666667%;
    border-right: 1px solid #fff;
    text-align: center;
}
.leftmenuclient .active {
    background: #32393d;
    color: #0077ae;
    font-weight: bold;
}
.rightmenuclient .navbar-nav{
    width: 100%;
}
.rightmenuclient{    
    margin: 0;
}
.rightmenuclient li{
    text-align: center;
}
.rightmenuclient li a{
    display: block;
    background: #717171;
    margin-bottom: 2px;
    color: #fff;
    padding: 4px 0;
}
.rightmenuclient li a:hover{
    color: #fff;
    background: #0077ae;
}
.headingRight{
    margin: 0;
    padding: 1.2rem;
    text-align: center;
    font-weight: bold;
}
.rightmenuclient .bigactive{
    font-weight: bold;
    color: #fff;
    background: #0077ae;
}
.redbg{    
    background: #ff6034;
}