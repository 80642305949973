* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%; 
  /* // 1rem = 10px */

  @include breakpoint(medium) {
    font-size: 56.25%;
     /* // 1rem = 9px */
  }

  @include breakpoint(small) {

    font-size: 50%; 
    /* // 1rem = 8px */
  }
}
body {
  /* font-family: "Poppins", sans-serif; */
  font-family:Arial, Helvetica, sans-serif;
}
a:hover{
  text-decoration: none;
}
/* login page start */
.main-container {
  height: 100vh;
  
  position: relative;
}
.login-page{
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px!important;
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
}
.loginarea{
  /* width: 512px; */
  background-color: #eaeaea;
  padding: 5rem;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
     box-shadow: 0 0 50px rgba(0,0,0,0.6);
    -moz-box-shadow: 0 0 50px rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 0 50px rgba(0,0,0,0.6);
    text-align: center;
}
.loginarea .logo{
  background-color: #fff;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
}
.loginarea .logo img{
  max-height:55px;
  height: auto;
}
.loginarea h4{
  color: #0077ae;
  text-rendering: auto!important;
  -webkit-font-smoothing: antialiased!important;
   text-shadow: 1px 1px 1px rgba(0,0,0,0.004)!important;
   font-weight: 600;
   margin: 0;
}
.loginarea a{
  color: #0F2582;
  font-size: 14px;
  margin-top: 10px;
}
.loginarea input{
  padding: .5rem .75rem;
    border: 1px solid #bfc4c4;
    padding: 0px 10px;
    height: 26px!important;
    margin-left: -1px;
    margin-top: 0px;
    border-radius: 0 3px 3px 0 !important;
    font-size: 1.4rem;
  min-height: 30px!important;
  /* margin-bottom: 3px!important; */
}
.input-group-addon {
  padding: .3rem .75rem;
  margin-bottom: 0;
  font-weight: 400;
  line-height:20px;
  color: #000000;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  margin-right: 4px;
  height: 30px;
  font-size: 1.6rem;
}
.form-control:focus {
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  border-color: rgb(0 0 0);
  outline: 0px;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 0px 0.2rem;
}
.logininner{
  width: 330px;
  margin: 0 auto;
  margin-top:1rem;
}
.maxtt{
  width: 330px;
  margin: 0 auto;
}
#lgnForm_LoginButton {
    background: #0077ae;
    color: #fff;
    width: 100%;
    margin: 0;
    cursor: pointer;
    border: 0;
    padding: 8px;
}
.maxwrap{
  max-width: 680px;
}
.loginfooter{
  position: relative;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 1rem;
  font-size: 1.4rem;
  bottom: 0;
}
.loginfooter p{
  margin: 0;
  padding: 0;
  color: #fff;
}
.loginfooter a{ 
  color: #0077ae;
}
.mtnew{
  margin-top: 1rem;
}
/* login page end */

@media only screen and (max-width: 768px) {
  .loginarea a {
    text-align: left;
    display: block;
}
  .loginarea {
    width: 92%;
  }
  .loginarea h4 {
    font-size: 1.4rem;
}
.logininner {
  width: 100%;
}
.logininner .input-group{
  width: auto;
}
.loginarea {
  width: 90%;
  background-color: #eaeaea;
  padding: 1rem;
  position: absolute;
  top: 42%;
  left: 4%;
  transform: translate(1%, -50%);
}
.maxtt {
  width: auto;
}
.error-login {
  position: absolute;
  top: 34px!important;
  line-height: normal;
  color: red;
  font-size: 13px;
  padding: 2px;
  left: 31px;
  font-weight: bold;
  text-transform: capitalize;
}
  }
  