@media only screen and (min-width: 250px){
.rightmenu .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    position: absolute;
    -webkit-transition: border-radius 2s;
}
}
@media(min-width:768px){
   
    .card-header {
        padding: 0!important;
        background: none!important;
    }
#accordionmain {
    display: block;
    margin: 0 10px;
}
#collapseOnemain {
    display: block!important;
}
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
  border-radius: 0px;
}

::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  background-color: #0077ae;
  border-radius: 0px;
  background-image: -webkit-linear-gradient(0deg,
                                            rgba(255, 255, 255, 0.5) 25%,
                        transparent 25%,
                        transparent 50%,
                        rgba(255, 255, 255, 0.5) 50%,
                        rgba(255, 255, 255, 0.5) 75%,
                        transparent 75%,
                        transparent)
}
}
@media(max-width:768px){
    body{
       background: #f2f8fb;
       font-size: 14px;
   }
    .wm100{
        width: 100%!important;
    }
    .leftmenuclient3 li {
        width: 100%;
    }
    input[type=file] {
        cursor: pointer;
        width: 200px;
      }
    .form-group {
        height: auto;
        display: flex;
    flex-wrap: wrap;
    }
    select.form-control:not([size]):not([multiple]) {
        height: auto;
        margin: 5px 0;
        width: 100%;
        padding: 8px 10px;
    }
    .smallheading {
        margin: 1rem 0;
        font-size: 16px;
        padding: 10px 10px;
    }
    #formpage .form-group label {
        width: auto;
        text-align: left;
    }
    .sidebar-nav li a{
        font-size: 16px;
    }
    .blackolor {
        overflow: auto;
    }
    .mainArea img{
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
    
    .btnwrp {
        position: static;
        right: 0px;
    }
    .btnwrp {
        text-align: right;
    }
    .tablebox {
        margin: 0;
        background: #fff;
        padding: 0;
        font-size: 15px;
    }
    #formpage .tablebox {
        background: #ffffff;
        /* margin-top: 0!important; */
        padding: 20px;
        max-width: 100%;
        margin: 0 auto;
        box-shadow: 2px 2px 2px #ccc!important;
        margin-bottom: 10px;
    }
    #formpage .tablebox2 {
        background: #ffffff;
        /* margin-top: 0!important; */
        padding: 20px;
        max-width: 100%;
        margin: 0 auto;
    }
    .loginfooternotmobfixed{
        position: relative;
    }
    .fix-topheader{
        position: relative;
    }
    table{
        white-space: nowrap;
    }
   .tablebox table button {
        background: #0a206c;
        color: #fff;
        border: none;
        /* margin-left: 10px; */
        margin: 2px;
        width: 40px;
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .adminmenu li {
        list-style: none;
    }
    .mainheading {
        max-width: 93.5%;
        font-size: 16px;
      }
      .newContainer {
        max-width: 100%;
        margin-left: 0%;
        padding-top: 0px!important;
    }
    .leftmenuclient li {
        width: 100%;
        margin-bottom: 2px;
        border: none;
    }
    .bottomarea {
        position: static;
    }
    .col-12.text-right.p-0 {
        margin-bottom: 10px;
    }

#formpage { display: flex; flex-direction: column; }
.lefeArea { order: 2; }
.rightArea { order: 1; margin-bottom: 10px; }
#formpage .navbar-nav {
    flex-direction: column;
}
.rightmenu .dropdown:hover .dropdown-menu {
    margin-top: 15px;
}
.rightmenuclient .navbar-nav {
    width: 93%;
    margin: 0 auto;
}
textarea#exampleFormControlTextarea1 {
    width: 200px!important;
}
#navbarNavAltMarkup{
    display: block;
}
.rightmenuclient button{
    background: #ccc;
    top: 0;
    right: 0;
}
.rightmenuclient .navbar-toggler {
    color: #fff;
    background: #0a206c;
    top: -72px!important;
    right: 20px;
}
#navbarSupportedContent .navbar-nav{
    width: 100%!important;
}
#navbarSupportedContent .navbar-nav li a{
    margin: 0;
}
.leftmenuclient{
    margin: 5px;
}
.headingRight{
    padding-bottom: 0;
}
.lefeArea {
    padding: 0.5rem 15px!important;
}
.mainheading {
    max-width: 100%;
    margin: 0;
}
.top_header{
    padding: 10px 0px;
}
.menubox{
    position: absolute;
}
.accountbox-wrap {
    max-width: 91%;
    width: 100%!important;
    z-index: 9999;
}
.accountbox-wrap ul li a {
    font-size: 14px;
}
.accountlist {
    height: 261px;
}
.row button{
    justify-content: center!important;
}
.account_search_wrap {
    display: block;
}
.btn{
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: normal!important;
}
.p-5{
    padding: 0!important;
}
.custom-control-inline {
    /* display: -webkit-inline-box; */
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
    width: 39%;
    float: left;
    text-align: left;
}
.rightArea img {
    max-width: 200px!important;
}
.rightmenuclient {
    margin-top: 10px;
}
#accordionmain .card-header{
    position: relative;
    padding: 0;
    padding-bottom: 15px;
    background: none;
}
#accordionmain .card-header:after {
    font-family: 'FontAwesome';  
    content: "\f068";
    float: right; 
    position: absolute;
    bottom: 20px;
    right: 80px;
}
#accordionmain .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f067"; 
}
.rightmenuclient li a {
    padding: 5px;
}
.buttonwrp button{
    margin-top: 0!important;
}
.buttonwrp a {
    font-size: 16px;
    padding: 7px 18px;
    margin: 2px;
    margin-top: 0;
}
.currency-icon img{
    float: left;
    margin-top: 4px;
}
.error {
    top: 36px;
    font-size: 13px;
}
.tablebox button{
    font-size: 16px;
    padding: 7px 18px;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
    font-size: 14px;
    padding: 7px 18px;
}
.heading{
    font-size: 16px;
}
.pagination {
    font-size: 14px;
    font-size: 14px;
    text-align: center;
    margin: 7px auto 4px;
}
.dataTables_info {
    text-align: center;
}
.form-control {
    font-size: 14px;
}
.form-group label {
    font-size: 14px;
}
.deskboxy {
    flex-direction: column;
}
.deskboxy .deskbox {
    margin-top: 20px;
}
.form-group {
    font-size: 16px;
    margin-bottom: 18px;
}
#lgnForm_LoginButton {
    font-size: 16px;
    padding: 2px;
}
.logininner .input-group{
    width: 100%;
}

.loginarea input{
    height: 35px!important;
    font-size: 16px;
}
.input-group-addon {
    padding: .3rem .75rem;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 28px;
    color: #000000;
    text-align: center;
    background-color: #eceeef;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    margin-right: 4px;
    height: 35px;
    font-size: 18px;
}
.form-control {
    font-size: 14px;
    /* width: auto; */
    height: auto;
    padding: 5px 10px;    
}
.table {
    font-size: 15px;
}
button.btn.btn-outline-secondary{
    font-size: 13px!important;
}

}