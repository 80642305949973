* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
   /* //1rem = 10px */

  @include breakpoint(medium) {
    font-size: 56.25%; 
    /* // 1rem = 9px */
  }

  @include breakpoint(small) {

    /* font-size: 50%; // 1rem = 8px */
  }
}
body {
  /* font-family: "Poppins", sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}
a:hover {
  text-decoration: none;
}
/* top header start */
.top_header {
  background: #0d2887;
  padding: 1rem;
  position: relative;
  z-index: 9999;
}
.top_header .nav-link {
  color: #fff;
  margin-left: 0.9rem;
  padding: 0;
  box-sizing: border-box;
}
.top_header .nav-link i {
  font-size: 2.5rem;
}
.top_header .nav-link .info-circle {
  font-size: 2.5rem;
}
.top_header .nav-link span {
  font-size: 1.2rem;
  position: relative;
  top: -5px;
  left: 5px;
}
.rightmenu {
  margin-top: 0.8rem;
}
.logo img {
  background: #fff;
  padding: 0.2rem 1.5rem;
  max-height: 40px;
}
.dropboxform {
  min-width: 25rem !important;
  padding: 2rem;
}
.dropboxform a {
  padding: 0 !important;
}
.dropboxform .social-btn .facebook-btn {
  background: #507cc0;
  padding: 0;
}
.dropboxform .social-btn .btn,
.dropboxform .social-btn .btn:hover {
  color: #fff;
  margin: 0;
  font-size: 13px;
  border: none;
  transition: all 0.4s;
  text-align: center;
  text-decoration: none;
  padding: 1rem 1rem !important;
}
.dropboxform .social-btn .facebook-btn {
  background: #507cc0;
}
.dropboxform .social-btn .facebook-btn:hover {
  background: #4676bd;
}
.dropboxform .social-btn .twitter-btn {
  background: #64ccf1;
}
.dropboxform .social-btn .twitter-btn:hover {
  background: #4ec7ef;
}
.dropboxform .social-btn .btn i {
  margin-right: 5px;
  font-size: 16px;
  position: relative;
  top: 2px;
}
#signupform {
  left: inherit !important;
  right: 0 !important;
}
/* top header end */
/* header start */

#navbarm {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}
#navbarm .nav-link {
  color: #fff;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem 1.2rem;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: smoothScroll 1s forwards;
  z-index: 999999;
}
.fixed .navbar {
  margin-top: 10px;
}
.dropdown-toggle::after {
  display: none;
}
#navbarm .nav-link i {
  font-size: 2.5rem;
  margin-top: 5px;
}
.dropdown-menu {
  background: #0d2887;
  border: none;
  border-radius: 0;
  margin-top: 0;
  min-width: 15rem;
}
.dropdown-item {
  color: #fff;
  font-size: 1.4rem;
}
#navbarm .nav-link:hover {
  background: #0d2887;
}
#navbarm .nav-link:focus {
  background: #0d2887 !important;
}
.dropdown-item:hover {
  background-color: #0a206c;
  color: #fff;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
.navbar-toggler {
  display: none;
}

/* Just for styling */
.styling {
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  /* height: 150px;
  overflow: auto; */
}
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: row;
  }
  .top_header .nav-link span {
    display: none;
  }
  .top_header .nav-link {
    margin-left: 1.4rem;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: none;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 0rem;
  }
  .navbar-toggler .fa-bars {
    font-size: 2rem;
    color: #fff;
  }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
    position: absolute;
    top: 16px;
  }
  #navbarm .navbar-nav {
    flex-direction: column;
  }
  .navbar-toggler {
    display: block;
  }
}
